/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AccountStatus = "ACCOUNT_APPROVED" | "ACCOUNT_CLOSED" | "ACCOUNT_NOT_INITIATED" | "ACCOUNT_PENDING" | "ACCOUNT_SUBMITTED" | "%future added value";
export type AccountType = "COMPANY" | "INDIVIDUAL" | "JOINT" | "SSD" | "TRUST" | "%future added value";
export type BankAccountStatus = "DIS" | "PEN" | "VER" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type TenantAccountType = "ADE" | "ADF" | "BDR" | "COM" | "COR" | "FXC" | "GST" | "IBF" | "INP" | "INT" | "MGN" | "OFE" | "PLF" | "PRM" | "REM" | "SPT" | "SUB" | "SUM" | "TRA" | "VUF" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountProfile_account$ref: FragmentReference;
declare export opaque type AccountProfile_account$fragmentType: AccountProfile_account$ref;
export type AccountProfile_account = {|
  +id: string,
  +name: ?string,
  +actualCashBase: ?number,
  +availableCashBase: ?number,
  +stockValueBase: ?number,
  +updatedAt: any,
  +actualCashBalances: ?$ReadOnlyArray<?{|
    +currency: ?Currency,
    +balance: ?number,
  |}>,
  +notes: ?{|
    +id: string
  |},
  +availableCashBalances: ?$ReadOnlyArray<?{|
    +currency: ?Currency,
    +balance: ?number,
  |}>,
  +holdings: ?$ReadOnlyArray<?{|
    +portfolio: {|
      +id: string,
      +trackingVue: ?{|
        +name: string
      |},
    |},
    +contract: {|
      +id: string,
      +symbol: string,
      +name: ?string,
    |},
    +quantity: number,
  |}>,
  +brokerageCredits: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +initialAmount: number,
        +remainingAmount: number,
      |}
    |}>
  |},
  +clientaccount: ?{|
    +id: string,
    +name: ?string,
    +accountStatus: AccountStatus,
    +accountType: ?AccountType,
    +createdAt: any,
    +updatedAt: any,
    +accruesInterest: boolean,
    +feeTemplate: ?{|
      +id: string,
      +name: string,
    |},
    +accountPtr: {|
      +id: string,
      +notes: ?{|
        +id: string
      |},
    |},
    +adviser: ?{|
      +id: string,
      +email: string,
      +firstName: string,
      +lastName: string,
      +parentTenant: {|
        +name: string,
        +id: string,
      |},
    |},
    +tenant: {|
      +id: string,
      +name: string,
    |},
    +referenceNumber: string,
    +userModels: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +username: string,
          +email: string,
          +firstName: string,
          +lastName: string,
        |}
      |}>
    |},
    +activeEmailRecipients: ?$ReadOnlyArray<?{|
      +id: string
    |}>,
    +tradingApplication: ?{|
      +id: string
    |},
  |},
  +bankAccounts: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +holderName: string,
        +bsbNumber: string,
        +accountNumber: string,
        +status: BankAccountStatus,
        +dividendPayout: boolean,
      |}
    |}>
  |},
  +tenantaccount: ?{|
    +name: ?string,
    +accountType: ?TenantAccountType,
    +createdAt: any,
    +updatedAt: any,
    +tenant: {|
      +id: string,
      +name: string,
      +email: string,
      +firstName: string,
      +lastName: string,
      +username: string,
    |},
  |},
  +$refType: AccountProfile_account$ref,
|};
export type AccountProfile_account$data = AccountProfile_account;
export type AccountProfile_account$key = {
  +$data?: AccountProfile_account$data,
  +$fragmentRefs: AccountProfile_account$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "balance",
    "args": null,
    "storageKey": null
  }
],
v4 = [
  (v0/*: any*/)
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "notes",
  "storageKey": null,
  "args": null,
  "concreteType": "NotesNode",
  "plural": false,
  "selections": (v4/*: any*/)
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accountType",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v8 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AccountProfile_account",
  "type": "AccountNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "actualCashBase",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "availableCashBase",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "stockValueBase",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actualCashBalances",
      "storageKey": null,
      "args": null,
      "concreteType": "MultiCurrencyNode",
      "plural": true,
      "selections": (v3/*: any*/)
    },
    (v5/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "availableCashBalances",
      "storageKey": null,
      "args": null,
      "concreteType": "MultiCurrencyNode",
      "plural": true,
      "selections": (v3/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "holdings",
      "storageKey": null,
      "args": null,
      "concreteType": "HoldingBalanceNode",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "portfolio",
          "storageKey": null,
          "args": null,
          "concreteType": "PortfolioNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "trackingVue",
              "storageKey": null,
              "args": null,
              "concreteType": "VueNode",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contract",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "symbol",
              "args": null,
              "storageKey": null
            },
            (v1/*: any*/)
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quantity",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "brokerageCredits",
      "storageKey": null,
      "args": null,
      "concreteType": "BrokerageCreditNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "BrokerageCreditNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "BrokerageCreditNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "initialAmount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "remainingAmount",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "clientaccount",
      "storageKey": null,
      "args": null,
      "concreteType": "ClientAccountNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "accountStatus",
          "args": null,
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "accruesInterest",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "feeTemplate",
          "storageKey": null,
          "args": null,
          "concreteType": "FeeTemplateNode",
          "plural": false,
          "selections": (v8/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "accountPtr",
          "storageKey": null,
          "args": null,
          "concreteType": "AccountNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "adviser",
          "storageKey": null,
          "args": null,
          "concreteType": "AdviserNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "parentTenant",
              "storageKey": null,
              "args": null,
              "concreteType": "TenantNode",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "tenant",
          "storageKey": null,
          "args": null,
          "concreteType": "TenantNode",
          "plural": false,
          "selections": (v8/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "referenceNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "userModels",
          "storageKey": null,
          "args": null,
          "concreteType": "UserModelNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "UserModelNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserModelNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v12/*: any*/),
                    (v9/*: any*/),
                    (v10/*: any*/),
                    (v11/*: any*/)
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "activeEmailRecipients",
          "storageKey": null,
          "args": null,
          "concreteType": "UserModelNode",
          "plural": true,
          "selections": (v4/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "tradingApplication",
          "storageKey": null,
          "args": null,
          "concreteType": "TradingApplicationNode",
          "plural": false,
          "selections": (v4/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "bankAccounts",
      "storageKey": null,
      "args": null,
      "concreteType": "BankAccountNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "BankAccountNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "holderName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "bsbNumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "accountNumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "dividendPayout",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tenantaccount",
      "storageKey": null,
      "args": null,
      "concreteType": "TenantAccountNode",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v2/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "tenant",
          "storageKey": null,
          "args": null,
          "concreteType": "TenantNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/)
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '5966814a41aed604eaabddd8cc584970';
module.exports = node;
