/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AccountType = "COMPANY" | "INDIVIDUAL" | "JOINT" | "SSD" | "TRUST" | "%future added value";
export type OrderStatus = "CAN" | "CRE" | "FIL" | "INI" | "INM" | "PEX" | "PFL" | "POS" | "QUE" | "REJ" | "%future added value";
export type PortfolioOrderSide = "BUY" | "REB" | "SELL" | "%future added value";
export type PortfolioOrderStatus = "CAN" | "COM" | "CRE" | "FIL" | "PEX" | "PFL" | "PRO" | "REJ" | "%future added value";
export type ReportState = "ERR" | "FIN" | "REQ" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PortfolioOrderList_viewer$ref: FragmentReference;
declare export opaque type PortfolioOrderList_viewer$fragmentType: PortfolioOrderList_viewer$ref;
export type PortfolioOrderList_viewer = {|
  +portfolioOrders: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +side: PortfolioOrderSide,
        +status: PortfolioOrderStatus,
        +createdAt: any,
        +updatedAt: any,
        +tradeInvoice: ?{|
          +id: string,
          +pdfUrl: ?string,
          +pdf: ?string,
          +state: ReportState,
          +createdAt: any,
          +updatedAt: any,
        |},
        +bulkOrder: ?{|
          +id: string
        |},
        +stockOrders: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +side: ?string,
              +quantityToDate: ?number,
              +matched: ?boolean,
              +quantity: ?number,
              +brokerOrder: ?{|
                +id: string,
                +quantity: ?number,
                +quantityFilled: ?number,
                +quantityLeft: ?number,
                +status: OrderStatus,
              |},
            |}
          |}>
        |},
        +portfolio: {|
          +id: string,
          +trackingVueName: ?string,
          +account: {|
            +name: ?string,
            +id: string,
            +clientaccount: ?{|
              +id: string,
              +accountType: ?AccountType,
            |},
          |},
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: PortfolioOrderList_viewer$ref,
|};
export type PortfolioOrderList_viewer$data = PortfolioOrderList_viewer;
export type PortfolioOrderList_viewer$key = {
  +$data?: PortfolioOrderList_viewer$data,
  +$fragmentRefs: PortfolioOrderList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "side",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PortfolioOrderList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "side",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "status",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "accountId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "trackingVue",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "portfolioOrders",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "accountId",
          "variableName": "accountId"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        },
        {
          "kind": "Variable",
          "name": "portfolio_TrackingVue",
          "variableName": "trackingVue"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "side",
          "variableName": "side"
        },
        {
          "kind": "Variable",
          "name": "status",
          "variableName": "status"
        }
      ],
      "concreteType": "PortfolioOrderNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "PortfolioOrderNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "PortfolioOrderNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tradeInvoice",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TradeInvoiceNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "pdfUrl",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "pdf",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "state",
                      "args": null,
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v4/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "bulkOrder",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BulkOrderNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "stockOrders",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StockOrderNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "StockOrderNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "StockOrderNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "quantityToDate",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "matched",
                              "args": null,
                              "storageKey": null
                            },
                            (v5/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "brokerOrder",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "BrokerOrderNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v5/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "quantityFilled",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "quantityLeft",
                                  "args": null,
                                  "storageKey": null
                                },
                                (v2/*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolio",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "trackingVueName",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "account",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "AccountNode",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "name",
                          "args": null,
                          "storageKey": null
                        },
                        (v0/*: any*/),
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "clientaccount",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ClientAccountNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "accountType",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9da8494fb651fcc9d2a9c3d830b1ab84';
module.exports = node;
