/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type FeeCalculationType = "MAX" | "MIN" | "SUM" | "%future added value";
export type FeeTemplateType = "DEF" | "OVR" | "%future added value";
export type FeeType = "ADF" | "ASB" | "AVH" | "BUF" | "CHI" | "CHIP" | "CHUP" | "COR" | "CRDT" | "FXB" | "GST" | "INT" | "MGN" | "MSB" | "PLF" | "PLT" | "PRO" | "RTA" | "RTP" | "STB" | "STBV" | "TAX" | "TRA" | "VHF" | "WDR" | "%future added value";
export type PaymentPeriod = "ANU" | "DAY" | "MON" | "ONE" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeeTemplate_viewer$ref: FragmentReference;
declare export opaque type FeeTemplate_viewer$fragmentType: FeeTemplate_viewer$ref;
export type FeeTemplate_viewer = {|
  +feeTemplates: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +description: string,
        +createdAt: any,
        +updatedAt: any,
        +type: FeeTemplateType,
        +notes: ?{|
          +id: string
        |},
        +fees: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +feeType: FeeType,
              +simpleTranche: boolean,
              +paymentPeriod: PaymentPeriod,
              +enabled: boolean,
              +createdAt: any,
              +updatedAt: any,
              +currency: ?Currency,
              +notes: ?{|
                +id: string
              |},
              +feeComponents: {|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +tenant: {|
                      +id: string,
                      +name: string,
                      +baseCurrency: Currency,
                    |},
                    +calculationType: FeeCalculationType,
                    +flatChargeAmount: ?number,
                    +maxChargePoint: ?number,
                    +minChargePoint: ?number,
                    +minPriceChargePoint: ?number,
                    +maxPriceChargePoint: ?number,
                    +percentChargeAmount: ?number,
                    +createdAt: any,
                    +updatedAt: any,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: FeeTemplate_viewer$ref,
|};
export type FeeTemplate_viewer$data = FeeTemplate_viewer;
export type FeeTemplate_viewer$key = {
  +$data?: FeeTemplate_viewer$data,
  +$fragmentRefs: FeeTemplate_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "notes",
  "storageKey": null,
  "args": null,
  "concreteType": "NotesNode",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ]
};
return {
  "kind": "Fragment",
  "name": "FeeTemplate_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "feeTemplateId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "feeTemplates",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "feeTemplateId"
        }
      ],
      "concreteType": "FeeTemplateNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "FeeTemplateNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "FeeTemplateNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "description",
                  "args": null,
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "fees",
                  "storageKey": "fees(orderBy:\"-createdAt\")",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "orderBy",
                      "value": "-createdAt"
                    }
                  ],
                  "concreteType": "FeeNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "FeeNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "FeeNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "feeType",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "simpleTranche",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "paymentPeriod",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "enabled",
                              "args": null,
                              "storageKey": null
                            },
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "currency",
                              "args": null,
                              "storageKey": null
                            },
                            (v4/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "feeComponents",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "FeeComponentNodeConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "FeeComponentNodeEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "FeeComponentNode",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "tenant",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "TenantNode",
                                          "plural": false,
                                          "selections": [
                                            (v0/*: any*/),
                                            (v1/*: any*/),
                                            {
                                              "kind": "ScalarField",
                                              "alias": null,
                                              "name": "baseCurrency",
                                              "args": null,
                                              "storageKey": null
                                            }
                                          ]
                                        },
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "calculationType",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "flatChargeAmount",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "maxChargePoint",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "minChargePoint",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "minPriceChargePoint",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "maxPriceChargePoint",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "percentChargeAmount",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        (v2/*: any*/),
                                        (v3/*: any*/)
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '418f74f9d9f6631493c2fc441b8f5405';
module.exports = node;
