import React from 'react';
import SSDIcon from '../SSDIcon';

export default function SSDColoredIcon(props) {
  return (
    <SSDIcon
      {...props}
      overrideStyle={{ fill: '#7CC57B' }}
    />
  );
}
