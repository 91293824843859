/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AccountType = "COMPANY" | "INDIVIDUAL" | "JOINT" | "SSD" | "TRUST" | "%future added value";
export type AdvancedOrderType = "BRA" | "NON" | "OCA" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type OrderApprovalContractStatus = "CAN" | "CRE" | "DIS" | "FAI" | "IND" | "PEN" | "%future added value";
export type OrderType = "LMT" | "MKT" | "SLMT" | "SMKT" | "TLMT" | "TMKT" | "%future added value";
export type ReportState = "ERR" | "FIN" | "REQ" | "%future added value";
export type Side = "BUY" | "SELL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type StockOrderList_viewer$ref: FragmentReference;
declare export opaque type StockOrderList_viewer$fragmentType: StockOrderList_viewer$ref;
export type StockOrderList_viewer = {|
  +stockOrders: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +orderPtr: {|
          +orderapprovalcontractSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +orderStatus: OrderApprovalContractStatus,
              |}
            |}>
          |}
        |},
        +id: string,
        +orderType: OrderType,
        +side: ?string,
        +updatedAt: any,
        +createdAt: any,
        +quantityToDate: ?number,
        +matched: ?boolean,
        +quantity: ?number,
        +cashQuantity: ?number,
        +blockedBaseCharges: ?number,
        +blockedHoldings: ?number,
        +blockedLocalCharges: ?number,
        +blockedProceeds: ?number,
        +advancedOrderType: AdvancedOrderType,
        +portfolioOrder: {|
          +id: string,
          +portfolio: {|
            +trackingVue: ?{|
              +name: string
            |}
          |},
        |},
        +contract: {|
          +symbol: string,
          +currency: Currency,
          +id: string,
          +exchange: {|
            +symbol: string
          |},
        |},
        +account: ?{|
          +id: string,
          +name: ?string,
          +clientaccount: ?{|
            +id: string,
            +accountType: ?AccountType,
          |},
        |},
        +status: ?string,
        +brokerOrder: ?{|
          +id: string,
          +matched: boolean,
          +quantity: ?number,
          +quantityFilled: ?number,
          +quantityLeft: ?number,
          +side: Side,
        |},
        +tradeInvoice: ?{|
          +id: string,
          +pdfUrl: ?string,
          +pdf: ?string,
          +state: ReportState,
          +createdAt: any,
          +updatedAt: any,
          +portfolioOrder: ?{|
            +id: string
          |},
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: StockOrderList_viewer$ref,
|};
export type StockOrderList_viewer$data = StockOrderList_viewer;
export type StockOrderList_viewer$key = {
  +$data?: StockOrderList_viewer$data,
  +$fragmentRefs: StockOrderList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "side",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "matched",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "StockOrderList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "side",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "statusIn",
      "type": "[String]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "exchange",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "accountId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "contractId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "orderType",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "algo",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "aot",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "stockOrders",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "accountId",
          "variableName": "accountId"
        },
        {
          "kind": "Variable",
          "name": "advancedOrderType",
          "variableName": "aot"
        },
        {
          "kind": "Variable",
          "name": "algo",
          "variableName": "algo"
        },
        {
          "kind": "Variable",
          "name": "contractId",
          "variableName": "contractId"
        },
        {
          "kind": "Variable",
          "name": "exchange",
          "variableName": "exchange"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        },
        {
          "kind": "Variable",
          "name": "orderType",
          "variableName": "orderType"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "side",
          "variableName": "side"
        },
        {
          "kind": "Variable",
          "name": "statusIn",
          "variableName": "statusIn"
        }
      ],
      "concreteType": "StockOrderNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "StockOrderNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "StockOrderNode",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "orderPtr",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "OrderNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "orderapprovalcontractSet",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "OrderApprovalContractNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "OrderApprovalContractNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "OrderApprovalContractNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "orderStatus",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "orderType",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantityToDate",
                  "args": null,
                  "storageKey": null
                },
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "cashQuantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "blockedBaseCharges",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "blockedHoldings",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "blockedLocalCharges",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "blockedProceeds",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "advancedOrderType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolioOrder",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioOrderNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "portfolio",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "PortfolioNode",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "trackingVue",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "VueNode",
                          "plural": false,
                          "selections": [
                            (v6/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contract",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContractNode",
                  "plural": false,
                  "selections": [
                    (v7/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "currency",
                      "args": null,
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "exchange",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ExchangeNode",
                      "plural": false,
                      "selections": [
                        (v7/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "account",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AccountNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v6/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "clientaccount",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ClientAccountNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "accountType",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "brokerOrder",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BrokerOrderNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "quantityFilled",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "quantityLeft",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tradeInvoice",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TradeInvoiceNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "pdfUrl",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "pdf",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "state",
                      "args": null,
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "portfolioOrder",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "PortfolioOrderNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bfc98589f021e93227e2bb2ab338d020';
module.exports = node;
